import {connect} from "react-redux";
import {logout_user} from "../../thunk/login";
import {Tab, Tabs} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

const mapStateToProps = (state) => ({
    routerHistory: state.routerHistory,
})
const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logout_user())
})

function Header({routerHistory}) {
    const history = useHistory();
    const [value, setValue] = useState(0);


    useEffect(() => {
        // if (routerHistory.location.pathname) {
        if (routerHistory.location.pathname && routerHistory.location.pathname !== "/main" && routerHistory.location.pathname === routerHistory.prevLocation.pathname) {
            history.push("/main");
            setValue(0);
            // switch (routerHistory.location.pathname) {
            //     case "/main":
            //         setValue(0);
            //         break;
            //     case "/charts":
            //         setValue(1);
            //         break;
            //     default:
            //         break;
            // }

        }
    }, [routerHistory]);


    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                history.push("/main");
                break;
            case 1:
                history.push("/charts");
                break;
            case 2:
                history.push("/statisticProc");
                break;
            case 3:
                history.push("/schedule");
                break;
            case 4:
                history.push("/settings");
                break;
            default:
                break;
        }
        setValue(newValue);
    };

    return (
        <>
            <Tabs value={value} onChange={(e, i) => {
                handleChange(e, i)
            }} aria-label="disabled tabs example">
                <Tab label="Main"/>
                <Tab label="Charts"/>
                <Tab label="Statistic"/>
                <Tab label="Notification Work"/>
                <Tab label="Settings"/>
            </Tabs>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);