import {connect} from "react-redux";
import style from "./closedOrders.module.scss";
import React, {useEffect} from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getClosedOrders} from "../../thunk/baseInfo";
import moment from "moment-timezone";

function ClosedOrders({
                          closeOrders,
                          getClosedOrders
                      }) {
    useEffect(() => {
        getClosedOrders();
    }, [])
    return (
        <>
            <h2>CLOSED ORDERS</h2>
            <div className={style.blockScroll}>

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Symbol</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Side</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Summ</TableCell>
                                <TableCell align="right">Index</TableCell>
                                <TableCell align="right">To</TableCell>
                                <TableCell align="right">Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {closeOrders.map((row) => (
                                <TableRow
                                    key={row.time}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.symbol}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.status}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.side}
                                    </TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">{row.executedQty}</TableCell>
                                    <TableCell align="right">{row.index}</TableCell>
                                    <TableCell align="right">{row.toSymbol}</TableCell>
                                    <TableCell
                                        align="right">{moment(row.time).format("YYYY/MM/DD HH:mm:ss")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    closeOrders: state.logs.closeOrders
});

const mapDispatchToProps = dispatch => ({
    getClosedOrders: () => dispatch(getClosedOrders())
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosedOrders);