import {
    SHOW_MODAL,
    ADD_THEME_MODAL
} from "../action/modal";

const stateModal = {
    show: 0,
    theme: ""
}

function users(state = stateModal, action) {
    switch (action && action.type) {
        case SHOW_MODAL:
            return {...state, show: action.data};
        case ADD_THEME_MODAL:
            return {...state, theme: action.data};
        default:
            return state;
    }
}

export default users;

