import {
    LOAD_HISTORY_LOGS,
    SET_INFO,
    INDEXES_FOR_JUMING_BEST_COIN,
    SET_BALANCE,
    UPDATE_CHART_PRICES,
    SET_CHART_PRICES,
    SET_CHART_INDEXES,
    UPDATE_CHART_INDEXES,
    CLOSE_ORDERS,
    UPDATE_CLOSE_ORDERS,
    LOAD_HISTORY_LOGS_ERROR,
    SET_INFO_ERROR,
    SET_BASE_INFO,
    SET_BASE_TRANDS,
    SET_SCHEDULE_WORK,
    SET_STATISTIC_PROC, SET_STATISTIC_PROC_SUMMARY, CLEAR_STATISTIC_PROC
} from "../action/logs";
import moment from "moment-timezone";

const data = {
    baseInfo: {
        totalBtc: null,
        totalUsd: null,
        yestPNLProc: null,
        yestPNLUsd: null,
        trands: {
            countPlusDynamicCoins: 1,
            countMinusDynamicCoins: 1
        }
    },
    info: "Start",
    infoError: "Start",
    warning: "",
    error: "",
    debug: "",
    indexes: [],
    balance: [],
    actualPairs: [],
    prices: [{
        name: 'Dataset 1',
        values: [
            {name: '2019-01', result: 10},
            {name: '2019-02', result: 40},
            {name: '2019-03', result: 30},
            {name: '2019-04', result: 20}
        ],
    }],
    statisticProc: {
        data: [],
        countAllDocs: 0,
        countPair: 0,
        activity: 0,
        success: 0,
        fail: 0
    },
    indexesChart: [],
    actualIndexesSymbol: [],
    closeOrders: [],
    scheduleWork: "",


}

function logs(state = data, action) {
    switch (action && action.type) {
        case LOAD_HISTORY_LOGS: {
            let logs = action.data.reduce((res, i) => {
                res = res + `${moment().format("DD/MM/YYYY HH:mm:ss")} ${i.type} - ${i.message}\n\n`;
                return res;
            }, "");
            return {...state, info: logs + state.info}
        }
        case LOAD_HISTORY_LOGS_ERROR: {
            let logsError = action.data.reduce((res, i) => {
                res = res + `${moment().format("DD/MM/YYYY HH:mm:ss")} ${i.type} - ${i.message}\n\n`;
                return res;
            }, "");
            return {...state, infoError: logsError + state.infoError}
        }
        case SET_INFO:
            return {
                ...state,
                info: state.info + `${moment().format("DD/MM/YYYY HH:mm:ss")} ${action.data.type} - ${action.data.message}\n\n`
            };
        case SET_INFO_ERROR:
            return {
                ...state,
                infoError: state.infoError + `${moment().format("DD/MM/YYYY HH:mm:ss")} ${action.data.type} - ${action.data.message}\n\n`
            };
        case SET_BALANCE:
            let {reportBalance} = action.data;
            reportBalance = reportBalance.sort((a, b) => {
                return b.summ - a.summ;
            });
            return {...state, balance: reportBalance};
        case INDEXES_FOR_JUMING_BEST_COIN:
            let {indexes} = action.data;
            //to back
            indexes = indexes.replace("{", "");
            indexes = indexes.replace("}", "");
            let array = indexes.split(",");
            let indexesArr = []
            if (array[0]) {
                indexesArr = array.reduce((acc, i) => {
                    let arr = [];
                    let bit = i.split("->");
                    bit[0] = bit[0].replace("<", "");
                    arr.push(bit[0]);
                    bit = bit[1].split(" :: ");
                    arr.push(bit[0]);
                    bit = bit[1].split(">: ");
                    arr.push(bit[0]);
                    arr.push(bit[1]);
                    let [from, to, someIndex, index] = arr;
                    acc.push({from, to, someIndex, index});
                    return acc;
                }, []);
                //end
                indexesArr = indexesArr.sort((a, b) => {
                    return b.index - a.index;
                })
            }

            return {...state, indexes: indexesArr};
        case SET_CHART_PRICES:
            let copyPrices = [...action.data.data];
            let resPrices = [];

            for (let item of copyPrices) {
                resPrices.push({name: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"), ...item.data});
            }
            return {...state, prices: resPrices, actualPairs: action.data.actualPairs}
        case UPDATE_CHART_PRICES: {
            let copyPrices = [...action.data.data];
            let copyPricesState = [...state.prices];
            let copyActualPairs = [...state.actualPairs];
            let resPrices = [];

            for (let item of copyPrices) {
                resPrices.push({name: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"), ...item.data});
            }

            copyPricesState.unshift();
            copyPricesState = [...copyPricesState, ...resPrices]

            for (let item of action.data.actualPairs) {
                if (!copyActualPairs.find(i => i === item)) {
                    copyActualPairs.push(item)
                }
            }

            return {...state, prices: [...copyPricesState], actualPairs: [...copyActualPairs]}
        }
        case SET_CHART_INDEXES: {
            let copyData = [...action.data.data];
            let {actualIndexesSymbol = undefined} = action.data;
            let data = [];

            for (let item of copyData) {
                let itemRes = {};
                itemRes.name = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss");

                for (let i of item.data) {
                    itemRes[i.to] = i.index;
                }
                data.push(itemRes);
            }

            if (actualIndexesSymbol) {
                return {...state, indexesChart: data, actualIndexesSymbol}
            } else {
                return {...state, indexesChart: data}
            }
        }
        case UPDATE_CHART_INDEXES: {

            let copyIndexesBd = {...action.data.indexesBd};
            let copyIndexesChart = [...state.indexesChart];
            let resIndexes = {};

            resIndexes.name = moment(copyIndexesBd.created_at).format("YYYY-MM-DD HH:mm:ss");

            for (let item of copyIndexesBd.data) {
                resIndexes[item.to] = item.index;
            }

            copyIndexesChart.unshift();
            copyIndexesChart.push(resIndexes);

            return {...state, indexesChart: copyIndexesChart}
        }
        case CLOSE_ORDERS: {
            let closeOrders = action.data.sort((a, b) => b.time - a.time)
            return {...state, closeOrders}
        }
        case UPDATE_CLOSE_ORDERS: {
            return {...state, closeOrders: [...state.closeOrders, action.data]}
        }
        case SET_BASE_INFO: {
            return {...state, baseInfo: {...state.baseInfo, ...action.data}}
        }
        case SET_BASE_TRANDS: {
            return {...state, baseInfo: {...state.baseInfo, trands: {...action.data}}}
        }
        case SET_SCHEDULE_WORK: {
            let res = [...action.data];
            res = res.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            res = res.map(item => {
                return `${moment(item.timestamp).format("DD/MM/YYYY HH:mm:ss")} ${item.type} - ${item.message}\n`;
            })
            res = res.join("");
            return {...state, scheduleWork: res}
        }
        case SET_STATISTIC_PROC: {
            let copyData = [...action.data.statisticProc];
            let data = [];
            let activity = action.data.countPair / action.data.countAllDocs * 100;
            copyData = copyData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            let countPlus = 0;
            let countMinus = 0;

            for (let item of copyData) {
                let itemRes = {};
                itemRes.name = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss");
                itemRes.value = item.trigger ? 5 : item.proc;
                data.push(itemRes);

                if (item.proc < 0) {
                    countMinus++
                } else {
                    countPlus++
                }
                ;
            }

            let fail = countMinus / action.data.countPair * 100;
            let success = countPlus / action.data.countPair * 100;

            return {
                ...state,
                statisticProc: {
                    data,
                    countAllDocs: action.data.countAllDocs,
                    countPair: action.data.countPair,
                    activity,
                    fail,
                    success
                }
            }
        }
        case SET_STATISTIC_PROC_SUMMARY: {
            // let test = {
            //     "statisticProc": [
            //         {
            //             "pair": "ALGOBUSD",
            //             "activity": 155,
            //             "success": 124,
            //             "fail": 31
            //         },
            //         {
            //             "pair": "XLMBUSD",
            //             "activity": 155,
            //             "success": 122,
            //             "fail": 33
            //         },
            //         {
            //             "pair": "DOGEBUSD",
            //             "activity": 155,
            //             "success": 125,
            //             "fail": 30
            //         },
            //         {
            //             "pair": "SHIBBUSD",
            //             "activity": 155,
            //             "success": 124,
            //             "fail": 31
            //         },
            //         {
            //             "pair": "LUNABUSD",
            //             "activity": 155,
            //             "success": 127,
            //             "fail": 28
            //         },
            //         {
            //             "pair": "DARBUSD",
            //             "activity": 155,
            //             "success": 127,
            //             "fail": 28
            //         }
            //     ],
            //     "countAllDocs": 930
            // };
            let copyData = [...action.data.statisticProc];
            // let copyData = [...test.statisticProc];
            let countAllDocs = action.data.countAllDocs;
            let data = {
                activityChart: [],
                successChart: [],
                failChart: [],
                statisticProc: []
            };

            for (let item of copyData) {
                let activity = item.activity / countAllDocs * 100;
                let success = item.success / item.activity * 100;
                let fail = item.fail / item.activity * 100;

                data.activityChart.push({
                    name: item.pair,
                    value: activity
                })
                data.successChart.push({
                    name: item.pair,
                    value: success
                })
                data.failChart.push({
                    name: item.pair,
                    value: fail
                })
                data.statisticProc.push({
                    pair: item.pair,
                    activity,
                    success,
                    fail
                })
            }

            return {
                ...state,
                statisticProc: data
            }
        }
        case CLEAR_STATISTIC_PROC:
            return {
                ...state,
                statisticProc: {}
            }
        default:
            return state;
    }
}

export default logs;

