import style from "./dateRangePicker.module.scss";
import {useState} from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

const DateRangePicker = function ({handleChange, defaultRange, use, setUse}) {
    const [value, onChange] = useState([defaultRange[0]._d, defaultRange[1]._d]);
    const handlerChange = (e) => {
        setUse(true);
        onChange(e);
        handleChange(e);
    }
    return (
        <div className={style.row}>
            <DateTimeRangePicker
                className={style.datePickerRow}
                onChange={handlerChange}
                disableClock={true}
                format="y/MM/dd HH:mm"
                value={use? value: [defaultRange[0]._d, defaultRange[1]._d]}
                id="date-range-picker"
            />
        </div>
    )
}

export default DateRangePicker;