import {combineReducers} from "redux";
import currentUser from "./token/login";
import routerHistory from "./router/router.js";
import modal from "./modal";
import logs from "./logs";
import config from "./config";

const reduserApp = combineReducers({
    currentUser,
    routerHistory,
    modal,
    logs,
    config
});

export default reduserApp;