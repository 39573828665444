import {connect} from "react-redux";
import style from "./baseInfo.module.scss";
import React, {useEffect, useMemo, useState} from "react";
import {backToBridge, getBaseTrands, getConfig, saveConfig} from "../../thunk/baseInfo";
import {Button, TextField, Switch} from "@mui/material";
import {checkBot, startBot, stopBot} from "../../thunk/navigate";

function BaseInfo({
                      baseInfo,
                      getBaseTrands,
                      trands,
                      getConfig,
                      config,
                      checkBot,
                      saveConfig,
                      startBot,
                      stopBot,
                      backToBridge
                  }) {
    let [countMDCProc, setCountMDCProc] = useState(50);
    let [countPDCProc, setCountPDCProc] = useState(50);
    let [statusWork, setStatusWork] = useState("#ff0000");

    let [trandForCloseInProc, setTrandForCloseInProc] = useState(0);
    let [optionTrandForCloseInProc, setOptionTrandForCloseInProc] = useState(false);

    let [procBackToBridgeMin, setProcBackToBridgeMin] = useState(0);
    let [procBackToBridgeMinOnOff, setProcBackToBridgeMinOnOff] = useState(false);

    let [procBackToBridgeMax, setProcBackToBridgeMax] = useState(0);
    let [procBackToBridgeMaxOnOff, setProcBackToBridgeMaxOnOff] = useState(false);

    let handleSaveConfig = () => {
        saveConfig({
            base_trand_for_close_in_proc: trandForCloseInProc,
            base_trand_for_close_in_proc_work: optionTrandForCloseInProc,
            procBackToBridgeMin: procBackToBridgeMin,
            procBackToBridgeMinOnOff: procBackToBridgeMinOnOff,
            procBackToBridgeMax: procBackToBridgeMax,
            procBackToBridgeMaxOnOff: procBackToBridgeMaxOnOff,
        })
    }

    const handleCheckBot = () => {
        checkBot()
            .then((res) => {
                setStatusWork(res ? "#00ff00" : "#ff0000")
            })
    }

    useMemo(() => {
        setTrandForCloseInProc(config.baseTrandForCloseInProc);
        setOptionTrandForCloseInProc(config.baseTrandForCloseInProcWork);

        setProcBackToBridgeMin(config.procBackToBridgeMin);
        setProcBackToBridgeMinOnOff(config.procBackToBridgeMinOnOff);
        setProcBackToBridgeMax(config.procBackToBridgeMax);
        setProcBackToBridgeMaxOnOff(config.procBackToBridgeMaxOnOff);
    }, [config]);

    useEffect(() => {
        getBaseTrands();
        getConfig();
        handleCheckBot();
    }, [])

    useMemo(() => {
        if (!trands) return;
        let {countPlusDynamicCoins, countMinusDynamicCoins} = trands;
        if (!countPlusDynamicCoins || !countMinusDynamicCoins) return;
        let allDynamicCoin = countPlusDynamicCoins + countMinusDynamicCoins;
        let oneProc = allDynamicCoin / 100;
        setCountMDCProc(countMinusDynamicCoins / oneProc);
        setCountPDCProc(countPlusDynamicCoins / oneProc);
    }, [trands])

    const handleChange = (event) => {
        setOptionTrandForCloseInProc(event.target.checked);
    };
    const handleChangeMin = (event) => {
        setProcBackToBridgeMinOnOff(event.target.checked);
    };
    const handleChangeMax = (event) => {
        setProcBackToBridgeMaxOnOff(event.target.checked);
    };

    return (
        <>
            <div className={style.baseInfo}>
                <h2>BASE INFO</h2>
                <div className={style.block}>
                    <ul>
                        <li>
                            <div className={style.title}>Total Balance</div>
                            {baseInfo.totalBtc} BTC / ~{baseInfo.totalUsd} USD
                        </li>
                        <li>
                            <div className={style.title}>
                                Daily Profit
                            </div>
                            {baseInfo.yestPNLProc} % / {baseInfo.yestPNLUsd} USD
                        </li>
                    </ul>

                    <div className={style.itemBlock}>
                        <div className={style.weatherPriceBlock}>
                            <div className={style.plus} style={{width: `${countPDCProc}%`}}></div>
                            <div className={style.minus} style={{width: `${countMDCProc}%`}}></div>
                        </div>
                        <br/>
                        <div
                            className={[
                                style.weatherPrice,
                                Math.round(countPDCProc) >= config.baseTrandForCloseInProc
                                    ? style.plus : style.minus
                            ].join(" ")}>
                            {Math.round(countPDCProc)}% / {config.baseTrandForCloseInProc}%
                        </div>
                    </div>


                </div>
            </div>
            <div className={style.itemBlockP}>
                <div>
                    <div className={style.blockConf}>
                        <div className={style.blockConfBlock}>
                            <label htmlFor="">Base proc when turn off bot's</label>
                            <TextField
                                id="outlined-required"
                                style={{width: 70}}
                                value={trandForCloseInProc || 0}
                                onChange={(e) => {
                                    setTrandForCloseInProc(e.target.value);
                                }}
                            />
                            <Switch
                                checked={optionTrandForCloseInProc || false}
                                value={optionTrandForCloseInProc || false}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </div>

                        <div className={style.blockConfBlock}>
                            <label htmlFor="">Min proc sell</label>
                            <TextField
                                id="outlined-required"
                                style={{width: 70}}
                                value={procBackToBridgeMin || 0}
                                onChange={(e) => {
                                    setProcBackToBridgeMin(e.target.value);
                                }}
                            />
                            <Switch
                                checked={procBackToBridgeMinOnOff || false}
                                value={procBackToBridgeMinOnOff || false}
                                onChange={handleChangeMin}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </div>

                        <div className={style.blockConfBlock}>
                            <label htmlFor="">Max proc sell</label>
                            <TextField
                                id="outlined-required"
                                style={{width: 70}}
                                value={procBackToBridgeMax || 0}
                                onChange={(e) => {
                                    setProcBackToBridgeMax(e.target.value);
                                }}
                            />
                            <Switch
                                checked={procBackToBridgeMaxOnOff || false}
                                value={procBackToBridgeMaxOnOff || false}
                                onChange={handleChangeMax}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </div>
                        <Button variant="contained" onClick={handleSaveConfig}>SAVE</Button>
                    </div>

                    <span className={style.separ}></span>
                    <Button variant="contained" onClick={() => {
                        stopBot()
                    }}>Stop</Button>
                    <Button variant="contained" onClick={() => {
                        startBot()
                    }}>Start</Button>
                    <span className={style.separ}></span>
                    <div className={style.check}>
                        <Button variant="contained" onClick={() => {
                            handleCheckBot()
                        }}>Check</Button>

                        <span style={{background: statusWork}}></span>
                    </div>
                    <span className={style.separ}></span>
                    <div className={style.check}>
                        <Button variant="contained" onClick={() => {
                            backToBridge()
                        }}>Back To Bridge</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    baseInfo: state.logs.baseInfo,
    trands: state.logs.baseInfo.trands,
    config: state.config.config,
});

const mapDispatchToProps = dispatch => ({
    getBaseTrands: () => dispatch(getBaseTrands()),
    getConfig: () => dispatch(getConfig()),
    checkBot: () => dispatch(checkBot()),
    saveConfig: (data) => dispatch(saveConfig(data)),
    startBot: () => dispatch(startBot()),
    stopBot: () => dispatch(stopBot()),
    backToBridge: () => dispatch(backToBridge()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseInfo);