import {connect} from "react-redux";
import style from "./Main.module.scss";
import {openSocketConnection} from "../../thunk/sockets";
import React, {useEffect} from "react";
import {getCookie} from "../../util/cookie";
import {checkToken} from "../../thunk/login";
import ConsoleBase from "../../components/console/consoleBase";
import Indexes from "../../components/indexes/indexes";
import Balances from "../../components/balance/balances";
import ChartPrices from "../../components/chartPrices/chartPrices";
import ChartIndexes from "../../components/chartIndexes/chartIndexes";
import ClosedOrders from "../../components/closedOrders/closedOrders";
import ConsoleError from "../../components/consoleError/consleError";
import Navigate from "../../components/navigate/navigate";
import BaseInfo from "../../components/baseInfo/baseInfo";

function Main({
                  openSocketConnection,
                  checkToken,
                  user,
                  indexes
              }) {

    useEffect(() => {
        const cookieToken = getCookie("token");
        if (cookieToken) {
            checkToken().then(() => {
                openSocketConnection(user.id);
            });
        }
    }, [])

    return (
        <div className={style.container}>
            <BaseInfo />
            <Indexes />
            <Balances />
            <ClosedOrders />
            {/*<div className={style.blockChart}>*/}
            {/*    <ChartPrices />*/}
            {/*</div>*/}
            {/*<div className={style.blockChart}>*/}
            {/*    <ChartIndexes />*/}
            {/*</div>*/}
            <br/>
            <ConsoleBase />
            {/*<ConsoleError />*/}
            <br/>
            <br/>
            <br/>


        </div>
    );
}

const mapStateToProps = state => ({
    user: state.currentUser.user,
    indexes: state.logs.indexes
});

const mapDispatchToProps = dispatch => ({
    checkToken: data => dispatch(checkToken(data)),
    openSocketConnection: data => dispatch(openSocketConnection(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);