import {connect} from "react-redux";
import style from "./indexes.module.scss"
import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

function Indexes({
                     indexes
                 }) {
    return (
        <>
            <h2>INDEXES</h2>
            <div className={style.blockScroll}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>From</TableCell>
                                <TableCell align="right">To</TableCell>
                                <TableCell align="right">Some Index</TableCell>
                                <TableCell align="right">INDEX</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {indexes.map((row) => (
                                <TableRow
                                    key={row.to}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.from}
                                    </TableCell>
                                    <TableCell align="right">{row.to}</TableCell>
                                    <TableCell align="right">{row.someIndex}</TableCell>
                                    <TableCell align="right">{row.index}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    indexes: state.logs.indexes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Indexes);