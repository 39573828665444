import io from "socket.io-client";
import {config} from "./config";

export const openSocket = (userid, arrayListenRooms) => {
    // console.log("machineToken - ", machineToken);
    const socket = io(config.socketUrl, {
        extraHeaders: {
            // authorization: getCookie("token"),
            userid: userid
        }
    });
    // console.log("Open socket connect");

    arrayListenRooms.forEach(item => {
        // console.log("item.room - ", item.room)
        socket.on(item.room, data => {
            item.fn(data);
        });
    });

    socket.on("disconnect", () => {
        // console.log("Disconnected");
        socket.disconnect();
        socket.close();
    });
    return socket;
}

export const socketConnectionRoom = (socket, room, fn) => {


    // socket.emit("webClientConnect", "lolkekazaza");

    // socket.on("webNoise", data => {
    // console.log(data);
    // });
    // socket.on("webAcc", data => {
    // console.log("data acc - ", data)

    // addSensorAcc(data);
    // });

}

// socketConnection();