import {openSocket} from "../util/socket";
import {
    indexesForJumpingToBestCoin,
    setBalance,
    setInfo,
    updateChartIndexes,
    updateChartPrices,
    updateCloseOrders, setInfoError
} from "../action/logs";
// import {setSocket} from "../action/socket";
// import {setBaseAlert, setBaseAlertSocket, setCurrentAlertSocket} from "../action/alert";
// import {updateDeviceInfo} from "../action/page";

export const openSocketConnection = (data: any) => (dispatch: any, getState: any) => {
    // const stateGlob = getState();
    // const {currentUser} = stateGlob;
    const arrayListenRooms = [
        {
            room: "logInfo",
            fn: (data: any) => {
                dispatch(setInfo(data));
                if(data.type === "ERROR") {
                    dispatch(setInfoError(data));
                }
            }
        },{
            room: "indexesForJumpingToBestCoin",
            fn: (data: any) => {
                dispatch(indexesForJumpingToBestCoin(data))
                // dispatch(updateChartIndexes(data))
            }
        },{
            room: "balance",
            fn: (data: any) => {
                dispatch(setBalance(data));
                // dispatch(updateChartPrices(data));
            }
        },{
            room: "price",
            fn: (data: any) => {
                dispatch(updateChartPrices(data));
            }
        },{
            room: "orderClosed",
            fn: (data: any) => {
                dispatch(updateCloseOrders(data))
            }
        }
    ]
    // const socket = openSocket(data, arrayListenRooms);
    openSocket(data, arrayListenRooms);
    // dispatch(setSocket({socket}));
}
