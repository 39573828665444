import {connect} from "react-redux";
import style from "./Settings.module.scss";
import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Switch, TextField} from "@mui/material";
import {saveConfig} from "../../thunk/baseInfo";
import {getAllCoins} from "../../thunk/logs";

function Settings({
                      config,
                      listCoins,
                      badgesCoins,
                      saveConfig,
                      getAllCoins
                  }) {
    const [localConfig, setLocalConfig] = useState({});
    const [badges, setBadges] = useState([]);

    let handlerRemoveBadge = (symbol) => {
        let copyBadges = [...badges];
        copyBadges = copyBadges.reduce((acc, item) => {
            if(item.symbol !== symbol) {
                acc.push(item);
            }
            return acc;
        }, []);
        setBadges(copyBadges);
    }

    const handlerAddBadge = (e) => {
        if(e.target.innerText) {
            let copyBadges = [...badges];
            copyBadges.push({
                index: copyBadges.length + 1,
                symbol: e.target.innerText
            })
            setBadges(copyBadges);
        }
    }

    let handleChangeConfig = (index, value) => {
        let val = value.target.value;
        if (index === "use_margin") {
            val = val ? "yes" : "no";
        }
        setLocalConfig({...localConfig, [index]: val});
    }

    let handleSaveConfig = () => {
        let copyBadges = [...badges];
        copyBadges = copyBadges.map(item => item.symbol);
        handleChangeConfig("current_coins", {target:{value:copyBadges}});

        let copyLocalConfig = {...localConfig}
        copyLocalConfig.coins = copyBadges;
        delete copyLocalConfig.api_key;
        delete copyLocalConfig.api_secret_key;
        saveConfig({
            ...copyLocalConfig
        })
    }

    useEffect(() => {
        if (config) {
            setLocalConfig(config);
        }
    }, [config])

    useEffect(() => {
        if(badgesCoins) {
            setBadges(badgesCoins)
        }
    }, [badgesCoins])

    useEffect(() => {
        getAllCoins();
    }, [])

    return (
        <div>
            <ul className={style.listBlock}>
                <li>
                    <span className={style.label}>current_coin</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("current_coin", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.current_coin}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>bridge</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("bridge", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.bridge}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>tld</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("tld", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.tld}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>hourToKeepScoutHistory</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("hourToKeepScoutHistory", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.hourToKeepScoutHistory}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>scout_sleep_time</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("scout_sleep_time", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.scout_sleep_time}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>use_margin</span>
                    <Switch
                        checked={localConfig.use_margin && localConfig.use_margin === "yes" ? true : false}
                        value={localConfig.use_margin && localConfig.use_margin === "yes" ? true : false}
                        onChange={(e) => {
                            handleChangeConfig("use_margin", e)
                        }}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                </li>

                <li>
                    <span className={style.label}>scout_multiplier</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("scout_multiplier", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.scout_multiplier}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>buy_timeout</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("buy_timeout", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.buy_timeout}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>sell_timeout</span>
                    <TextField
                        onChange={(e) => {
                            handleChangeConfig("sell_timeout", e)
                        }}
                        id="outlined-size-small"
                        value={localConfig.sell_timeout}
                        size="small"
                    />
                </li>
                <li>
                    <span className={style.label}>current_coins</span>
                    {/*{localConfig.current_coins && localConfig.current_coins.join(" ")}*/}
                    <div className={style.badgesRow}>
                        {badges.length && badges.map(item => (
                            <div className={style.item}>
                                <span>{item.symbol}</span>
                                <div
                                    className={style.close}
                                    key={"symbol"+item.symbol}
                                    onClick={() => {handlerRemoveBadge(item.symbol)}}
                                />
                            </div>
                        ))}
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className={style.autocomplete}
                            options={listCoins}
                            onChange={handlerAddBadge}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Coins" />}
                        />
                    </div>
                </li>
            </ul>
            <Button variant="contained" onClick={handleSaveConfig}>SAVE</Button>
        </div>
    );
}

const mapStateToProps = state => ({
    config: state.config.config,
    listCoins: state.config.listCoins,
    badgesCoins: state.config.badgesCoins
});

const mapDispatchToProps = dispatch => ({
    saveConfig: (data) => dispatch(saveConfig(data)),
    getAllCoins: (data) => dispatch(getAllCoins(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);