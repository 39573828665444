export const getCookie = (name: any) => {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export const setCookie = (name: any, value: any, days: any) => {
    const d: any = new Date();
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}

export const deleteCookie = (name: any) => { setCookie(name, '', -1); }