import style from "./default.module.scss";
import {connect} from "react-redux";
import Header from "../../components/header/header";
const mapStateToProps = state => ({
    router: state.routerHistory

});
const mapDispatchToProps = () => ({});
function DefaultLayout({children}) {
    return (
        <div >
            <Header />
            <div className={style.row}>
                {children}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);