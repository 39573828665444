export const SET_CONFIG = "SET_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const SET_LIST_COINS = "SET_LIST_COINS";

export function setConfig(data) {
    return {
        type: SET_CONFIG,
        data
    }
}

export function updateConfig(data) {
    return {
        type: UPDATE_CONFIG,
        data
    }
}

export function setListConfig(data) {
    return {
        type: SET_LIST_COINS,
        data
    }
}