import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect, Route, withRouter, useLocation} from "react-router-dom";
import {checkToken} from "../thunk/login";
import {setRouterHistory} from "../action/router";
import {setToken} from "../action/login";
import {getCookie} from "../util/cookie";

const mapStateToProps = state => ({
    token: state.currentUser.token.token,
    router: state.routerHistory
});
const mapDispatchToProps = dispatch => ({
    setRouterHistory: data => dispatch(setRouterHistory(data)),
    checkToken: data => dispatch(checkToken(data)),
    setToken: data => dispatch(setToken(data))
});

const Auth = ({setToken, token, router, checkToken, setRouterHistory, path, component: Component, layout: Layout}) => {
    const locationHook = useLocation();

    const [verif, setVerif] = useState((props) => {})
    const handlerCheckToken = async () => {
        let {location, prevLocation} = router;
        const cookieToken = getCookie("token");

        if(token) return true;
        await setToken(cookieToken);

        // eslint-disable-next-line no-throw-literal
        if (!cookieToken) throw false;
        if (prevLocation.pathname === location.pathname) {
            let ct = await checkToken({cookieToken})
            if(ct.success) {return true} else {return false};
            // if (!ct.success) {
            // eslint-disable-next-line no-throw-literal
            // if (!refreshToken) throw false;
            // eslint-disable-next-line no-throw-literal
            // ct =  await refreshingToken(refreshToken).then(() => true).catch(() => {throw false});
            // }
            // return ct;
        } else {
            return true;
        }
    }

    useEffect(() => {
        setRouterHistory(locationHook);

        handlerCheckToken().then(
            () => {
                setVerif((props) =>
                    (<Layout>
                        <Component {...props} />
                    </Layout>)
                )
            })
            .catch(
                () => {
                    setVerif((props) => (<Redirect to='/'/>))
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            )}, [locationHook])

    // const result = (props) => handlerCheckToken().then(() => (
    //     <Layout>
    //         <Component {...props} />
    //     </Layout>)).catch(() => (<Redirect to='/'/>))

    return (<Route
        path={path}
        render={props => verif}
    />)
//ToDo Trying send props
    // return (<Route
    //     path={path}
    //     render={props => result(props)}
    //
    // />)
};
export const AuthRoute = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Auth)
);
// render={props => (
//     handlerCheckToken() ?
//         <Layout>
//             <Component {...props} />
//         </Layout> :
//         <Redirect to='/'/>
// )}