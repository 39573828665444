import {connect} from "react-redux";
import {login} from "../../thunk/login";
import {Button, Container, Stack, TextField} from "@mui/material";
import {useState} from "react";
import {useHistory} from "react-router-dom";

function Login({login}) {
    const history = useHistory();
    const [email, setEmail] = useState(false);
    const [pass, setPass] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [passErr, setPassErr] = useState(false);

    const handlerLogin = () => {
        login({email, password: pass})
            .then(() => {
                history.push("/main");
            })
            .catch(() => {
                setEmailErr(true);
                setPassErr(true);
            })
    }
    return (
        <>
            <Container maxWidth="sm"
                       sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: "center"}}>
                <Stack spacing={2}>
                    <TextField
                        id="outlined-required"
                        label="Login"
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailErr(false);
                        }}
                    />
                    <TextField
                        id="outlined-required"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={(e) => {
                            setPass(e.target.value);
                            setPassErr(false);
                        }}
                    />
                    <Button variant="contained" onClick={handlerLogin}>Log in</Button>
                </Stack>
            </Container>
        </>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    login: data => dispatch(login(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);