import {connect} from "react-redux";
import style from "./balances.module.scss";
import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

function Balances({
                      balance
                  }) {
    return (
        <>
            <h2>BALANCES</h2>
            <div className={style.blockScroll}>
                <TableContainer component={Paper} >
                    <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Symbol</TableCell>
                                {/*<TableCell align="right">Pair Symbol</TableCell>*/}
                                <TableCell align="right">Balance</TableCell>
                                <TableCell align="right">Locked Balance</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Summ in Bridge</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {balance.map((row) => (
                                <TableRow
                                    key={row.symbol}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.symbol}
                                    </TableCell>
                                    {/*<TableCell align="right">{row.pairSymbol}</TableCell>*/}
                                    <TableCell align="right">{row.free}</TableCell>
                                    <TableCell align="right">{row.locked}</TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">{row.summ}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    balance: state.logs.balance
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Balances);