import style from "./filterButtonUI.module.scss"
import {useRef, useState} from "react";
import {Button} from "@mui/material";
// import {SaveAlt} from "@material-ui/icons";
// import Loader from "../loader/loader";

function FilterButtonUI({
                            download = false,
                            handleClickFn,
                            handleClickArg = {},
                            handleClickThen,
                            className,
                            label = "",
                            loader = 1
                        }) {
    const autoDownloadFile = useRef(null);
    const [urlCsv, setUrlCsv] = useState("/");

    const handleClick = () => {
            handleClickFn(handleClickArg).then((data) => {
                handleClickThen && handleClickThen(data);
                setUrlCsv(data.data.path);
                if(autoDownloadFile && autoDownloadFile.current) {
                    autoDownloadFile.current.click();
                }
            })
    }

    return (
        <>
            <a href={urlCsv} className={style.hideLink} ref={autoDownloadFile} download>qwe</a>
            <Button
                variant="contained"
                className={[
                    className,
                ].join(" ")}
                onClick={handleClick}>
                {label}
                {/*{download && (*/}
                {/*    <div>Save</div>*/}
                {/*)}*/}
            </Button>
        </>
    );
}

export default FilterButtonUI;