import React from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import style from "./root.module.scss"
import Login from "./login/login";
import Main from "./Main/Main";
import {AuthRoute} from "../util/route";
import DefaultLayout from "../layouts/default/default";
import Charts from "./Charts/Chart";
import ScheduleWork from "./ScheduleWork/ScheduleWork";
import Settings from "./Settings/Settings";
import StatisticProc from "./StatisticProc/StatisticProc";

function ScreensRoot() {

    return (
        <div className={style.app}>
            <Switch>
                <Route exact path="/" component={Login}/>
                {/*<Route exact path="/" component={EpidInc}/>*/}
                <AuthRoute path="/main" component={Main} layout={DefaultLayout}/>
                <AuthRoute path="/charts" component={Charts} layout={DefaultLayout}/>
                <AuthRoute path="/schedule" component={ScheduleWork} layout={DefaultLayout}/>
                <AuthRoute path="/settings" component={Settings} layout={DefaultLayout}/>
                <AuthRoute path="/statisticProc" component={StatisticProc} layout={DefaultLayout}/>
                {/*<AuthRoute path="/tables-user" component={TableUsers} layout={Default}/>*/}
                {/*<AuthRoute path="/search-contact" component={EpidInc} layout={Default}/>*/}
            </Switch>
        </div>
    );
}

// const mapStateToProps = state => ({
//     csvList: state.csvList
// });

// export default connect(
//     mapStateToProps,
//     {}
// )(ScreensRoot);

export default ScreensRoot;