import {connect} from "react-redux";
import React, {useEffect, useRef} from "react";
import {getScheduleWork} from "../../thunk/logs";

function ScheduleWork({
                          getScheduleWork,
                          scheduleWork,
                          indexes
                      }) {

    useEffect(() => {
        getScheduleWork()
    }, []);

    let textarea = useRef();

    return (
        <div>
            <h2>Notification work</h2>

            <textarea
                aria-label="empty textarea"
                placeholder="Empty"
                disabled={true}
                value={scheduleWork}
                ref={textarea}
                style={{width: 1000, height: 500}}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    scheduleWork: state.logs.scheduleWork
});

const mapDispatchToProps = dispatch => ({
    getScheduleWork: data => dispatch(getScheduleWork(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleWork);