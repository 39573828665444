import {connect} from "react-redux";
import style from "./barChartBrush.module.scss";
import React, {useEffect, useState} from "react";
import {AreaChart, BarChart, CartesianGrid, Legend, Bar, Tooltip, XAxis, YAxis, Brush, Area} from "recharts";

let colors = [
    '#07259d',
    '#8f550e',
    '#2cad1b',
    '#24b480',
    '#a70fee',
    '#076009',
    '#eeba0f',
    '#370c4b',
    '#63f659',
    '#e81092',
    '#faf624',
    '#810549',
    '#cd8dea',
    '#f5021d',
    '#2266f8',
    '#ef512c',
    '#f581d0',
    '#43f8c7',
    '#5af1a0',
    '#13b8ab',
    '#b6b6b6',
    '#5b5b5b'
]

function BarChartBrush({
                           data,
                           widthContainer = 1200,
                           heightContainer = 440,
                           xAxixInterval = "preserveEnd",
                           brush = true
                       }) {
    let [index, setIndex] = useState(0);

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={style.customTooltip}>
                    <div className={style.label}>{label}</div>
                    <div className={style.body}>
                        <div className={style.item}>
                            <label>{payload[0].name}</label>:
                            <span>{payload[0].value.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className={style.rowChart}>
            {data.length && (
                <BarChart
                    width={widthContainer}
                    height={heightContainer}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid stroke="#b6b6b6"/>
                    <XAxis
                        dataKey="name"
                        tick={{
                            fontSize: 12
                        }}
                        interval={xAxixInterval}
                    />
                    <YAxis
                        yAxisId="left"
                        tick={{
                            fontSize: 12
                        }}
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar
                        yAxisId="left"
                        isAnimationActive={false}
                        dot={false}
                        type="monotone"
                        dataKey={"value"}
                        fill={colors[index]}
                    />
                    {brush && (
                        <Brush
                            dataKey="name"
                            height={30}
                            stroke="#b9b9b9"
                            data={data}
                            y={410}
                            x={150}
                            width={900}
                            tickFormatter={{
                                fontSize: 14
                            }}
                        >
                            <BarChart
                                data={data}
                            >
                                <Bar
                                    isAnimationActive={false}
                                    dataKey={"value"}
                                    fill="#5b5b5b"
                                    type="monotone"
                                />
                            </BarChart>
                        </Brush>
                    )}
                </BarChart>
            )}
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BarChartBrush);