import {connect} from "react-redux";
import style from "./lineChartBrush.module.scss";
import React, {useEffect, useState} from "react";
import {AreaChart, LineChart, CartesianGrid, Legend, Line, Tooltip, XAxis, YAxis, Brush, Area} from "recharts";

let colors = [
    '#8f550e',
    '#2cad1b',
    '#24b480',
    '#a70fee',
    '#076009',
    '#07259d',
    '#eeba0f',
    '#370c4b',
    '#63f659',
    '#e81092',
    '#faf624',
    '#810549',
    '#cd8dea',
    '#f5021d',
    '#2266f8',
    '#ef512c',
    '#f581d0',
    '#43f8c7',
    '#5af1a0',
    '#13b8ab',
    '#b6b6b6',
    '#5b5b5b'
]

function LineChartBrush({
                            data,
                            legend,
                            selectOne = false,
                            brush = true
                        }) {
    let [index, setIndex] = useState(0);
    let [opacity, setOpacity] = useState({});


    let handleLegend = (dataKey) => {
        let copyOpacity = {...opacity}
        if (!selectOne) {
            copyOpacity[dataKey] = copyOpacity[dataKey] ? 0 : 1;
        } else {
            for (let i in copyOpacity) {
                copyOpacity[i] = 0;
            }
            copyOpacity[dataKey] = 1;
        }
        setOpacity(copyOpacity)
    }

    const renderLegend = (props) => {
        return (
            <ul className={style.legend}>
                {
                    legend.map((entry, index) => (
                        <li
                            key={`item-${index}`}
                            onClick={() => {
                                handleLegend(entry)
                            }}
                            style={{background: colors[index]}}
                            className={[!opacity[entry] ? style.noactive : ""].join(" ")}
                        >{entry}</li>
                    ))
                }
            </ul>
        );
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={style.customTooltip}>
                    <div className={style.label}>{label}</div>
                    <div className={style.body}>
                        <div class={style.item}>
                            <label>{payload[0].name}</label>:
                            <span>{payload[0].value}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    let initOpacity = () => {
        let res = {}
        if (!selectOne) {
            for (let i of legend) {
                if (opacity[i] === undefined) {
                    res[i] = 1;
                } else {
                    res[i] = opacity[i]
                }
            }
        } else {
            let first = 0;
            let symbolsC = [];
            for (let i of legend) {
                symbolsC = [...symbolsC, i];
                if (opacity[i] === undefined) {
                    res[i] = 0;
                } else {
                    first = first + 1;
                    res[i] = opacity[i]
                }
            }
            if (!first) {
                res[symbolsC[0]] = 1;
            }
        }
        setOpacity(res);
    }

    useEffect(() => {
        if (legend.length) {
            initOpacity();
        }
    }, [legend]);

    return (
        <div className={style.rowChart}>
            {data.length && (
                <LineChart
                    width={1200}
                    height={440}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid stroke="#b6b6b6"/>
                    <XAxis
                        dataKey="name"
                        tick={{
                            fontSize: 12
                        }}
                    />
                    <YAxis
                        yAxisId="left"
                        tick={{
                            fontSize: 12
                        }}
                    />
                    {/*<YAxis*/}
                    {/*    yAxisId="right"*/}
                    {/*    orientation="right"*/}
                    {/*    */}
                    {/*/>*/}
                    <Tooltip content={<CustomTooltip />}/>
                    {legend.map((item, index) => {
                            return opacity[item] && (
                                // <div key={"chartBrushContainer" + index}>
                                //     (
                                <Line
                                    yAxisId="left"
                                    isAnimationActive={false}
                                    dot={false}
                                    type="monotone"
                                    key={"chartBrush" + item}
                                    dataKey={item}
                                    stroke={colors[index]}
                                />
                                // )}
                                // </div>
                            )
                        }
                    )}
                    <Legend content={renderLegend}/>
                    {brush && (
                        <Brush
                            dataKey="name"
                            height={30}
                            stroke="#b9b9b9"
                            data={data}
                            y={410}
                            x={150}
                            width={900}
                            tickFormatter={{
                                fontSize: 14
                            }}
                        >
                            <AreaChart
                                data={data}
                            >
                                {legend.map((item, index) => {
                                        return opacity[item] && (
                                            <Area
                                                isAnimationActive={false}
                                                key={"chartBrushBrush" + item}
                                                dataKey={item}
                                                stroke="#5b5b5b" fill="#fff"
                                                type="monotone"
                                            />
                                        )
                                    }
                                )}
                            </AreaChart>
                        </Brush>
                    )}
                </LineChart>
            )}
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LineChartBrush);