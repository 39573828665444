import {get} from "../util/Api"
import {setChartPrices} from "../action/logs"

export const getLogsBase = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/prices", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setChartPrices(data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}