import {get, post} from "../util/Api"
import {setBaseInfo, setBaseTrands, setCloseOrders} from "../action/logs"
import {setConfig} from "../action/config";

export const getClosedOrders = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        get("v1/accountInfo/base", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setCloseOrders(data.data.lastCloseOrders));
                dispatch(setBaseInfo({
                    totalBtc: data.data.totalBtc,
                    totalUsd: data.data.totalUsd,
                    yestPNLProc: data.data.yestPNLProc,
                    yestPNLUsd: data.data.yestPNLUsd
                }))
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getBaseTrands = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        get("v1/getBaseTrands", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setBaseTrands({
                    countPlusDynamicCoins: data.data.countPlusDynamicCoins,
                    countMinusDynamicCoins: data.data.countMinusDynamicCoins
                }));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getConfig = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        get("v1/configFile", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setConfig(data.data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const saveConfig = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        post("v1/configFile", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const backToBridge = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        post("v1/backToBridge", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}