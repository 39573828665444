import {connect} from "react-redux";
import {showModal} from "../../../action/modal";

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(dispatch(showModal(0)))
});
function ModalAddOneWorker() {
    return (
        <>
            modal 1
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddOneWorker);