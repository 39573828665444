import axios from "axios";
import {getCookie} from "./cookie";
import {config} from "./config";

// const baseUri = 'http://localhost:3001/web/';
export const post = (uri: any, data: any, conf: any = {}) => {
    let headers = {
        "x-auth": getCookie("token")
    }
    if(conf.headers) {
        headers = conf.headers;
    }
    return axios.post(`${config.baseUrl}${uri}`, data, {...conf, headers} ).then((data: any) => {
        if(data.status !== 200) {
            throw new Error("false");
        }
        return data.data
    });
}

export const deletep = (uri: any, data:any, conf: any = {}) => {
    let headers = {
        "x-auth": getCookie("token")
    }
    if(conf.headers) {
        headers = conf.headers;
    }
    return axios.delete(`${config.baseUrl}${uri}`, {data, headers} ).then((data: any) => {
        if(data.status !== 200) {
            throw new Error("false");
        }
        return data.data
    });
}

export const get = (uri: any, data: any, conf: any = {}) => {
    let headers = {
        "x-auth": getCookie("token")
    }
    if(conf.headers) {
        headers = conf.headers;
    }
    return axios.get(`${config.baseUrl}${uri}`, {params:data, headers}).then((data: any) => {
        if(data.status !== 200) {
            throw false;
        }
        return data.data
    });
}
