import {post, deletep} from "../util/Api"
import {setUser} from "../action/login";
import {setCookie, deleteCookie} from "../util/cookie";

export const login = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/login", params, {withCredentials: true}).then(data => {
            const {status} = data;
            if (status === "ok") {
                localStorage.setItem("refreshToken", data.data.token.refreshToken);
                setCookie("token", data.data.token.token, 1);
                dispatch(setUser(data.data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const checkToken = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/checkAccessToken", params).then(data => {
            dispatch(setUser(data.data));
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

export const logout_user = (data: any) => {
    return new Promise((resolve, reject) => {
        deletep("userAccountLogout", data).then(data => {
            deleteCookie('token');
            localStorage.removeItem("refreshToken");

            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}
