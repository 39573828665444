export const SET_INFO = "SET_INFO";
export const SET_INFO_ERROR = "SET_INFO_ERROR";
export const SET_WARNING = "SET_WARNING";
export const SET_ERROR = "SET_ERROR";
export const SET_DEBUG = "SET_DEBUG";
export const LOAD_HISTORY_LOGS = "LOAD_HISTORY_LOGS";
export const LOAD_HISTORY_LOGS_ERROR = "LOAD_HISTORY_LOGS_ERROR";
export const INDEXES_FOR_JUMING_BEST_COIN = "INDEXES_FOR_JUMING_BEST_COIN";
export const SET_BALANCE = "SET_BALANCE";
export const SET_CHART_PRICES = "SET_CHART_PRICES";
export const UPDATE_CHART_PRICES = "UPDATE_CHART_PRICES";
export const SET_CHART_INDEXES = "SET_CHART_INDEXES";
export const UPDATE_CHART_INDEXES = "UPDATE_CHART_INDEXES";
export const CLOSE_ORDERS = "CLOSE_ORDERS";
export const UPDATE_CLOSE_ORDERS = "UPDATE_CLOSE_ORDERS";
export const SET_BASE_INFO = "SET_BASE_INFO";
export const SET_BASE_TRANDS = "SET_BASE_TRANDS";
export const SET_SCHEDULE_WORK = "SET_SCHEDULE_WORK";
export const SET_STATISTIC_PROC = "SET_STATISTIC_PROC";
export const SET_STATISTIC_PROC_SUMMARY = "SET_STATISTIC_PROC_SUMMARY";
export const CLEAR_STATISTIC_PROC = "CLEAR_STATISTIC_PROC";

export function setInfo(data) {
    return {
        type: SET_INFO,
        data
    }
}

export function setInfoError(data) {
    return {
        type: SET_INFO_ERROR,
        data
    }
}

export function setWarning(data) {
    return {
        type: SET_WARNING,
        data
    }
}

export function setError(data) {
    return {
        type: SET_ERROR,
        data
    }
}

export function setDebug(data) {
    return {
        type: SET_DEBUG,
        data
    }
}

export function loadHistoryLogs(data) {
    return {
        type: LOAD_HISTORY_LOGS,
        data
    }
}

export function loadHistoryLogsError(data) {
    return {
        type: LOAD_HISTORY_LOGS_ERROR,
        data
    }
}

export function indexesForJumpingToBestCoin(data) {
    return {
        type: INDEXES_FOR_JUMING_BEST_COIN,
        data
    }
}

export function setBalance(data) {
    return {
        type: SET_BALANCE,
        data
    }
}

export function setChartPrices(data) {
    return {
        type: SET_CHART_PRICES,
        data
    }
}

export function updateChartPrices(data) {
    return {
        type: UPDATE_CHART_PRICES,
        data
    }
}

export function setChartIndexes(data) {
    return {
        type: SET_CHART_INDEXES,
        data
    }
}

export function updateChartIndexes(data) {
    return {
        type: UPDATE_CHART_INDEXES,
        data
    }
}

export function setCloseOrders(data) {
    return {
        type: CLOSE_ORDERS,
        data
    }
}

export function updateCloseOrders(data) {
    return {
        type: UPDATE_CLOSE_ORDERS,
        data
    }
}

export function setBaseInfo(data) {
    return {
        type: SET_BASE_INFO,
        data
    }
}

export function setBaseTrands(data) {
    return {
        type: SET_BASE_TRANDS,
        data
    }
}

export function setScheduleWork(data) {
    return {
        type: SET_SCHEDULE_WORK,
        data
    }
}

export function setStatisticProc(data) {
    return {
        type: SET_STATISTIC_PROC,
        data
    }
}

export function setStatisticProcSummary(data) {
    return {
        type: SET_STATISTIC_PROC_SUMMARY,
        data
    }
}

export function clearStatisticProc(data) {
    return {
        type: CLEAR_STATISTIC_PROC,
        data
    }
}