import {connect} from "react-redux";
import {openSocketConnection} from "../../thunk/sockets";
import React, {useEffect} from "react";
import {getCookie} from "../../util/cookie";
import style from "./Charts.module.scss";
import {checkToken} from "../../thunk/login";
import ChartPrices from "../../components/chartPrices/chartPrices";
import ChartIndexes from "../../components/chartIndexes/chartIndexes";

function Charts({
                  openSocketConnection,
                  checkToken,
                  user
              }) {

    useEffect(() => {
        // const cookieToken = getCookie("token");
        // if (cookieToken) {
            // checkToken().then(() => {
            //     openSocketConnection(user.id);
            // });
        // }
    }, [])

    return (
        <div className={style.container}>
            {/*<div className={style.blockChart}>*/}
            {/*    <ChartPrices />*/}
            {/*</div>*/}
            <div className={style.blockChart}>
                <ChartIndexes />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.currentUser.user,
    indexes: state.logs.indexes
});

const mapDispatchToProps = dispatch => ({
    checkToken: data => dispatch(checkToken(data)),
    openSocketConnection: data => dispatch(openSocketConnection(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);