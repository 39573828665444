import {Button} from "@mui/material";
import {connect} from "react-redux";
import {startBot, stopBot} from "../../thunk/navigate";

function Navigate({startBot, stopBot}) {

    return (
        <>
            <Button variant="contained" onClick={() => {
                stopBot()
            }}>Stop</Button>
            <Button variant="contained" onClick={() => {
                startBot()
            }}>Start</Button>
        </>
    )
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    startBot: () => dispatch(startBot()),
    stopBot: () => dispatch(stopBot()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigate);