import {createStore, compose, applyMiddleware} from "redux";
import thunk from 'redux-thunk';
import reducerApp from "../reducer/reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = function () {
    return createStore(
        reducerApp,
        composeEnhancer(applyMiddleware(thunk))
    )
};

export default configureStore;