import {connect} from "react-redux";
import style from "./statisticProc.module.scss"
import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import BarChartBrush from "../barChartBrush/barChartBrush";

function StaisticProcSimple({
                                statisticProc
                            }) {
    return (
        <>
            {statisticProc.data && statisticProc.data.length && (
                <div>
                    <TableContainer>
                        <Table sx={{maxWidth: 300}} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{statisticProc.countPair} / {statisticProc.countAllDocs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Activity</TableCell>
                                    <TableCell align="right">Success</TableCell>
                                    <TableCell align="right">Fail</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {statisticProc.activity.toFixed(2)}%
                                    </TableCell>
                                    <TableCell align="right">{statisticProc.success.toFixed(2)}%</TableCell>
                                    <TableCell align="right">{statisticProc.fail.toFixed(2)}%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <BarChartBrush
                        data={statisticProc.data}
                        selectOne={true}
                        brush={true}
                    />
                </div>
            )}
        </>
    );
}

const mapStateToProps = state => ({
    indexes: state.logs.indexes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StaisticProcSimple);