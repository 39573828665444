// import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import style from "./footer.module.scss";
import {logout_user} from "../../thunk/login";

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(logout_user())
})

function Footer() {
    // const history = useHistory();
    // const handlerLogOut = () => {
    //     logoutUser().then(() => {
    //         history.push("/");
    //     })
    //
    // }
    return (
        <footer className={style.containerFooter}>

            <div className={style.block}>
                {/*Canario 2020©*/}
            </div>
        </footer>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);