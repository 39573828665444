import {connect} from "react-redux";
import React, {useEffect} from "react";
import {getLogsBase} from "../../thunk/prices";
import LineChartBrush from "../lineChartBrush/lineChartBrush";

function ChartPrices({
                         prices,
                         getLogsBase,
                         actualPairs
                     }) {
    useEffect(() => {
        getLogsBase();
    }, [])

    return (
        <>
            <h2>PRICES CHART</h2>

            {prices.length && (
                <LineChartBrush
                    data={prices}
                    legend={actualPairs}
                />
            )}
        </>
    );
}

const mapStateToProps = state => ({
    prices: state.logs.prices,
    actualPairs: state.logs.actualPairs
});

const mapDispatchToProps = dispatch => ({
    getLogsBase: () => dispatch(getLogsBase())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartPrices);