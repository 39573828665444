import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {getIndexesBd, selectIndexesBd} from "../../thunk/indexes";
import LineChartBrush from "../lineChartBrush/lineChartBrush";
import moment from "moment-timezone";
import DateRangePicker from "../UI/dateRangePicker/dateRangePicker";
import {Button} from "@mui/material";
import style from "./chartIndexes.module.scss";
function ChartIndexes({
                          indexesChart,
                          getIndexesBd,
                          actualIndexesSymbol,
                          selectIndexesBd
                      }) {
    const [defaultRange, setDefaultRange] = useState([moment().subtract(20, "minutes"), moment()]);
    const [use, setUse] = useState(0);

    useEffect(() => {
        getIndexesBd();
    }, []);

    const handleCurrent = () => {
        getIndexesBd();
        if (indexesChart[0]) {
            let startDate = moment(indexesChart[0].name);
            let endDate = moment(indexesChart[indexesChart.length - 1].name)
            setDefaultRange([startDate, endDate]);
        }
    }

    const handlerDate = (e) => {
        setDefaultRange(e);
    }

    let handleFind = () => {
        selectIndexesBd({
            dateFrom: defaultRange[0],
            dateTo: defaultRange[1]
        })
    }

    return (
        <>
            <h2>INDEXES CHART</h2>
            <div className={style.nav}>

                <DateRangePicker
                    handleChange={handlerDate}
                    defaultRange={defaultRange}
                    use={use}
                    setUse={setUse}
                />
                <Button variant="contained" onClick={() => {
                    handleFind()
                }}>Find</Button>
                <Button variant="contained" onClick={() => {
                    handleCurrent()
                }}>Current</Button>
            </div>
            {indexesChart.length && (
                <LineChartBrush
                    data={indexesChart}
                    legend={actualIndexesSymbol}
                    selectOne={true}
                    brush={true}
                />
            )}

        </>
    );
}

const mapStateToProps = state => ({
    indexesChart: state.logs.indexesChart,
    actualIndexesSymbol: state.logs.actualIndexesSymbol
});

const mapDispatchToProps = dispatch => ({
    getIndexesBd: () => dispatch(getIndexesBd()),
    selectIndexesBd: (data) => dispatch(selectIndexesBd(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartIndexes);