import {connect} from "react-redux";
import ModalAddOneWorker from "../modalAddOneWorker/modalAddOneWorker"
import ModalUploadFileWithUsers from "../modalUploadFileWithUsers/modalUploadFileWithUsers"
import {showModal} from "../../../action/modal";
import style from "./modalLayout.module.scss";
//ToDo Create normal layout, send Theme
const mapStateToProps = state => ({
    modal: state.modal
});

const mapDispatchToProps = dispatch => ({
    showModal: data => dispatch(showModal(data))
});

function Modal({modal, showModal}) {

    const handlerCloseModal = () => {
        showModal(0);
    }

    return (
        <>
            {modal.show ? (
                <div className={style.container}>
                    <div className={style.bg} onClick={() => {
                        handlerCloseModal()
                    }} />

                    <div className={style.modalRow}>
                        {/*<div className={style.canarioModalImg}>*/}
                        {/*    <img src={canarioModal} alt=""/>*/}
                        {/*</div>*/}
                        {modal.theme === "modalAddOneWorker" ? (<ModalAddOneWorker/>) : ""}
                        {modal.theme === "modalUploadFileWithUsers" ? (<ModalUploadFileWithUsers/>) : ""}
                    </div>
                </div>
            ) : ""}
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);