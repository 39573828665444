import {connect} from "react-redux";
import style from "./pieChartBrush.module.scss";
import {PieChart, Pie, Cell} from "recharts";

let colors = [
    '#07259d',
    '#8f550e',
    '#2cad1b',
    '#24b480',
    '#a70fee',
    '#076009',
    '#eeba0f',
    '#370c4b',
    '#63f659',
    '#e81092',
    '#faf624',
    '#810549',
    '#cd8dea',
    '#f5021d',
    '#2266f8',
    '#ef512c',
    '#f581d0',
    '#43f8c7',
    '#5af1a0',
    '#13b8ab',
    '#b6b6b6',
    '#5b5b5b'
]
const RADIAN = Math.PI / 180;
function PieChartBrush({
                           data,
                           widthContainer = 1200,
                           heightContainer = 440,
                       }) {

    const renderCustomizedLabel = ({ x, y, cx, cy, midAngle, innerRadius, outerRadius, value, name, index }) => {
        // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        // const x = cx + radius * Math.cos(-midAngle * RADIAN) ;
        // const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} fontSize={10} dominantBaseline="central">
                {`${name} - ${value.toFixed(2)}%`}
            </text>
        );
    };

    return (
        <div className={style.rowChart}>
            {data.length && (
                <PieChart
                    width={widthContainer}
                    height={heightContainer}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        isAnimationActive={false}
                        outerRadius={80}
                        label={renderCustomizedLabel}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index]}/>
                        ))}
                    </Pie>
                </PieChart>
            )}
        </div>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PieChartBrush);