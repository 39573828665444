import {get, post} from "../util/Api"
import {setChartIndexes} from "../action/logs"

export const getIndexesBd = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/indexes", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setChartIndexes(data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const selectIndexesBd = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/indexes", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setChartIndexes(data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}