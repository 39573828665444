import moment from "moment-timezone";
import {SET_CONFIG, UPDATE_CONFIG, SET_LIST_COINS} from "../action/config";

const data = {
    config: {},
    badgesCoins: {
        data: [
            {
                index: 1,
                symbol: "default"
            }
        ],
    },
    listCoins: [
    ]
}

function config(state = data, action) {
    switch (action && action.type) {
        case SET_CONFIG: {
            let currentCoins = action.data.current_coins.map((item, index) => {return {
                index,
                symbol: item
            }});
            return {...state, config: {...action.data}, badgesCoins: currentCoins}
        }
        case UPDATE_CONFIG: {
            return {...state}
        }
        case SET_LIST_COINS: {
            let copyData = action.data.coins.map(item => item.symbol);
            return {...state, listCoins: copyData}
        }
        default:
            return state;
    }
}

export default config;

