import {connect} from "react-redux";
import style from "./statisticProc.module.scss"
import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import BarChartBrush from "../barChartBrush/barChartBrush";
import PieChartBrush from "../pieChartBrush/pieChartBrush";

function StaisticProcSummary({
                                data
                            }) {
    return (
        <>
            <TableContainer>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>From</TableCell>
                            {data.statisticProc.length && data.statisticProc.map((item, index) => (
                                <TableCell key={"pair" + index}>{item.pair}</TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <b>Activity</b>
                            </TableCell>
                            {data.statisticProc.length && data.statisticProc.map((item, index) => (
                                <TableCell component="th" scope="row"
                                           key={"activity" + index}>{item.activity.toFixed(2)}%</TableCell>))}
                        </TableRow>
                        <TableRow
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <b>Success</b>
                            </TableCell>
                            {data.statisticProc.length && data.statisticProc.map((item, index) => (
                                <TableCell component="th" scope="row"
                                           key={"success" + index}>{item.success.toFixed(2)}%</TableCell>))}
                        </TableRow>
                        <TableRow
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <b>Fail</b>
                            </TableCell>
                            {data.statisticProc.length && data.statisticProc.map((item, index) => (
                                <TableCell component="th" scope="row"
                                           key={"fail" + index}>{item.fail.toFixed(2)}%</TableCell>))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <br/>

            <div className={style.charts}>
                {data.successChart && data.successChart.length && (
                    <div>
                        <h4>Success</h4>
                        <BarChartBrush
                            data={data.successChart}
                            widthContainer={window.screen.width*(3.5/10)}
                            xAxixInterval={0}
                            selectOne={true}
                            brush={false}
                        />
                    </div>

                )}

                {data.failChart && data.failChart.length && (
                    <div>
                        <h4>Fail</h4>
                        <BarChartBrush
                            data={data.failChart}
                            widthContainer={window.screen.width*(3.5/10)}
                            xAxixInterval={0}
                            selectOne={true}
                            brush={false}
                        />
                    </div>

                )}

                {data.activityChart && data.activityChart.length && (
                    <div>
                        <h4>Activity</h4>
                        <PieChartBrush
                            data={data.activityChart}
                            widthContainer={window.screen.width*(2.8/10)}
                        />
                    </div>

                )}
            </div>


        </>
    );
}

const mapStateToProps = state => ({
    indexes: state.logs.indexes
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StaisticProcSummary);