import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import configureStore from "./store/state";
import './index.css';
import ScreensRoot from './screens/root';
import Footer from "./components/footer/footer";
import Modal from "./components/modals/layouts/modalLayout"

const renderApp = preloadedState => {
    const store = configureStore(preloadedState);
    render(
        <Provider store={store}>
            <BrowserRouter>
                <ScreensRoot/>
                <Footer/>
                <Modal />
            </BrowserRouter>
        </Provider>,
        document.querySelector('#root')
    )
};


(() => renderApp())();