import {connect} from "react-redux";
import {showModal} from "../../../action/modal";

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(showModal(0)),
    // addUsers: data => dispatch(upload_one_users(data))
});

function ModalAddOneWorker() {

    return (
        <>
            {/*<div className="modalHeader">'הוספת מכשיר מס</div>*/}
dfs;lkgjsdfl;kgj;slkfjg
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddOneWorker);