import {
    SET_USER,
    SET_TOKEN
} from "../../action/login";

const user = {
    user: {},
    token: {
        token: "",
        refreshToken: ""
    }
}

function currentUser(state = user, action) {
    switch (action && action.type) {
        case SET_USER:
            return {...state, ...action.data};
        case SET_TOKEN:
            state.token.token = action.data;
            return state;
        default:
            return state;
    }
}

export default currentUser;

