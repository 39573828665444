import {
    SET_ROUTER_HISTORY
} from "../../action/router";
const {pathname, search, hash} = document.location;
const router = {
    location: {
        pathname,
        search,
        hash,
        state: undefined,
        key:""
    },
    prevLocation: {}
}

function routerRedux(state = router, action) {
    switch (action && action.type) {
        case SET_ROUTER_HISTORY:
            state.prevLocation = state.location.key !== action.data.key ?
                state.location: state.prevLocation;
            state.location = action.data;

            return {...state};
        default:
            return state;
    }
}

export default routerRedux;

