import {post} from "../util/Api"

export const startBot = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/startBot", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}
export const stopBot = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/stopBot", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}
export const checkBot = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        post("v1/checkWorkBot", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(data.data.running)
            } else {
                reject(data);
            }
        })
    });
}