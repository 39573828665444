import {connect} from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, Fade, Paper, Popper} from "@mui/material";
import {getLogsBase} from "../../thunk/logs";

function ConsoleBase({
                         logInfo,
                         getLogsBases
                     }) {
    const textarea = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    useMemo(() => {
        if (
            textarea.current &&
            textarea.current.scrollTop >=
            (textarea.current.scrollHeight - (textarea.current.offsetHeight + 150))
        ) {
            textarea.current.scrollTop = textarea.current.scrollHeight;
        }
    }, [logInfo])

    useEffect(() => {
        // getLogsBases()
    }, [])

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        setTimeout(() => {
            if(textarea.current) {
                textarea.current.scrollTop = textarea.current.scrollHeight;
            }
        }, 400)

    };

    return (
        <>
            <Button onClick={handleClick('bottom-end')}>Console</Button>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                               <textarea
                                   aria-label="empty textarea"
                                   placeholder="Empty"
                                   disabled={true}
                                   value={logInfo}
                                   ref={textarea}
                                   style={{width: 1000, height: 500}}
                               />
                        </Paper>
                    </Fade>
                )}
            </Popper>

        </>
    );
}

const mapStateToProps = state => ({
    logInfo: state.logs.info,
});

const mapDispatchToProps = dispatch => ({
    getLogsBases: () => dispatch(getLogsBase())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleBase);