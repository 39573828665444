import {deletep, get} from "../util/Api"
import {
    loadHistoryLogs,
    clearStatisticProc,
    loadHistoryLogsError,
    setScheduleWork,
    setStatisticProc,
    setStatisticProcSummary
} from "../action/logs"
import {setListConfig} from "../action/config";

export const getLogsBase = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/logs/logsBase", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(loadHistoryLogs(data.data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getLogsError = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/logs/logsError", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(loadHistoryLogsError(data.data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getScheduleWork = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/logs/scheduleWork", params).then(data => {
            const {status} = data;
            if (status === "ok") {

                dispatch(setScheduleWork(data.data));
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getStatisticProc = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/statisticProc/statisticProc", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                if (data.data.countPair) {
                    dispatch(setStatisticProc(data.data));
                } else {
                    dispatch(setStatisticProcSummary(data.data));
                    // dispatch(setStatisticProcSummary());
                }
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const removeStatisticProc = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        deletep("v1/statisticProc/removeStatistic", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(clearStatisticProc());
                resolve(status)
            } else {
                reject(data);
            }
        })
    });
}

export const getStatisticProcCsv = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/statisticProc/csv", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                resolve(data)
            }
        })
    });
}

export const getAllCoins = (params: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        get("v1/coins/all", params).then(data => {
            const {status} = data;
            if (status === "ok") {
                dispatch(setListConfig(data.data));
                resolve(data)
            }
        })
    });
}