export const config = {
    baseUrl: "https://bot-dev-api.ar-sw.com/app/",
    // baseUrl: "http://localhost:3002/app/",
    // baseUrl: "https://canario-api2.ar-sw.com/web/",
    baseSourceUrl: "https://bot-dev-api.ar-sw.com",
    // baseSourceUrl: "http://localhost:3002",
    // baseSourceUrl: "https://canario-api2.ar-sw.com",
    // baseUrl: "http://localhost:3002/web/",
    socketUrl: "https://bot-dev-api.ar-sw.com",
    // socketUrl: "https://canario-api2.ar-sw.com",
    // socketUrl: "http://localhost:3100",
};