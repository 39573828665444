import {connect} from "react-redux";
import style from "./StatisticProc.module.scss"
import React, {useEffect, useState} from "react";
import {getStatisticProc, getStatisticProcCsv, removeStatisticProc} from "../../thunk/logs";
import {getIndexesBd} from "../../thunk/indexes";
import StaisticProcSummary from "../../components/staisticProc/staisticProcSummary";
import StaisticProcSimple from "../../components/staisticProc/staisticProcSimple";
import {Button} from "@mui/material";
import FilterButtonUI from "../../components/UI/download/filterButtonUI";

function StatisticProc({
                           getIndexesBd,
                           actualIndexesSymbol = [],
                           getStatisticProc,
                           config,
                           statisticProc,
                           removeStatisticProc,
                           getStatisticProcCsv
                       }) {
    let [currentPair, setCurrentPair] = useState();

    let handlerGetStatisticProc = (pair) => {
        setCurrentPair(pair);
        let sym = pair === "SUMMARY" ? pair : pair + config.bridge;
        getStatisticProc({pair: sym});
    }

    useEffect(() => {
        getIndexesBd()
    }, []);

    useEffect(() => {
        if (actualIndexesSymbol.length) {
            setCurrentPair(actualIndexesSymbol[0]);
            getStatisticProc({pair: actualIndexesSymbol[0] + config.bridge});
        }
    }, [actualIndexesSymbol]);

    return (
        <div>
            <h2>Statistic Procent</h2>
            <h3>IN WORKING</h3>
            <br/>
            <div className={style.legendRow}>
                {actualIndexesSymbol.length && actualIndexesSymbol.map((item, index) => (
                    <div
                        onClick={() => {
                            handlerGetStatisticProc(item)
                        }}
                        key={item}
                        className={[style.legendItem, currentPair === item ? style.active : ""].join(" ")}
                    >
                        {item}
                    </div>
                ))}
                <div
                    onClick={() => {
                        handlerGetStatisticProc("SUMMARY")
                    }}
                    className={[style.legendItem, currentPair === "SUMMARY" ? style.active : ""].join(" ")}
                >
                    SUMMARY
                </div>
            </div>

            <div className={style.buttons}>
                <Button variant="contained" onClick={removeStatisticProc}>
                    Clear Staristic
                </Button>
                <FilterButtonUI
                    label={"Download Csv"}
                    handleClickFn={getStatisticProcCsv}
                />
            </div>
            {currentPair === "SUMMARY" && statisticProc.activityChart && statisticProc.activityChart.length && (
                <StaisticProcSummary
                    data={statisticProc}
                />
            )}
            {currentPair !== "SUMMARY" && statisticProc.data && statisticProc.data.length && (
                <StaisticProcSimple
                    statisticProc={statisticProc}
                />
            )}


        </div>
    );
}

const mapStateToProps = state => ({
    actualIndexesSymbol: state.logs.actualIndexesSymbol,
    config: state.config.config,
    statisticProc: state.logs.statisticProc,

});

const mapDispatchToProps = dispatch => ({
    getIndexesBd: () => dispatch(getIndexesBd()),
    getStatisticProcCsv: () => dispatch(getStatisticProcCsv()),
    removeStatisticProc: () => dispatch(removeStatisticProc()),
    getStatisticProc: (data) => dispatch(getStatisticProc(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticProc);